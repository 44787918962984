import Vue from 'vue';
import VueRouter from 'vue-router';
import { SmartStorage } from 'smart-core-util';
//城市车队路由
const tenantIndex = () => import('../views/tenant/index.vue');
const items = () => import('../views/tenant/items.vue');
const carAccount = () => import('../views/tenant/carAccount.vue');
const cityEditOrder = () => import('../views/tenant/editOrder.vue');
const carDetail = () => import('../views/tenant/carDetail.vue');
const supplerList = () => import('../views/tenant/supplerList.vue');
const cityBatchApproval = () => import('../views/tenant/cityBatchApproval.vue');

//网约车路由
const addPlatform = () => import('../views/platformCar/addPlatform.vue');
const budgetList = () => import('../views/platformCar/budgetList.vue');
const platformIndex = () => import('../views/platformCar/index.vue');
const platformPriceCoupon = () => import('../views/platformCar/platformPriceCoupon.vue');
const orderDetail = () => import('../views/platformCar/orderDetail.vue');
const platformEditOrder = () => import('../views/platformCar/editOrder.vue');
const openauth = () => import('../views/platformCar/openauth.vue');
const batchApproval = () => import('../views/platformCar/batchApproval.vue');



//线上租车
// const onlineRental = () => import('../views/tenant/onlineRental.vue')
const onlineAddPlatform = () => import('../views/onlineRental/addPlatform.vue');
const onlineBudgetList = () => import('../views/onlineRental/budgetList.vue');
const onlineRental = () => import('../views/onlineRental/index.vue');
const onlinePlatformPriceCoupon = () => import('../views/onlineRental/platformPriceCoupon.vue');
const onlineOrderDetail = () => import('../views/onlineRental/orderDetail.vue');
const onlinePlatformEditOrder = () => import('../views/onlineRental/editOrder.vue');
const onlineOpenauth = () => import('../views/onlineRental/openauth.vue');
const onlineEditOrder = () => import('../views/onlineRental/editOrder.vue');

// 进程路由
const projectProcess = () => import('../views/common/projectProcess.vue');

//住友用车审批
const approvalIndex = () => import('../views/approval/approvalIndex.vue');
const approvalList = () => import('../views/approval/approvalList.vue');
const approvalPassenger = () => import('../views/approval/approvalPassenger.vue');
const approvalLog = () => import('../views/approval/approvalLog.vue');

//默沙东详情页
const allCarOrder = () => import('../views/msd/allCarOrder.vue');

Vue.use(VueRouter);
//官方vue-router@3.0及以上新版本路由默认回调返回的都是promise，原先就版本的路由回调将废弃！！！！
const originalPush = VueRouter.prototype.push;

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};
//城市车队路由
const tenantRouters = [
  {
    path: '/cityIndex',
    name: 'cityIndex',
    component: tenantIndex, //城市车队列表
    meta: {
      keepAlive: SmartStorage.get('viewOnly')
    }
  },
  {
    path: '/items',
    name: 'items',
    component: items //城市车队需求列表
  },
  {
    path: '/cityBatchApproval',
    name: 'cityBatchApproval',
    component: cityBatchApproval //城市车队批量审批
  },
  {
    path: '/cityEditOrder',
    name: 'cityEditOrder',
    component: cityEditOrder //城市车队需求列表
  },
  {
    path: '/item',
    name: 'supplerList',
    component: supplerList //城市车队确认报价
  },
  {
    path: '/supplerList',
    name: 'supplerList',
    component: supplerList //城市车队确认报价
  },
  {
    path: '/carDetail',
    name: 'carDetail',
    component: carDetail //城市车队确认报价
  },

  {
    path: '/carAccount',
    name: 'carAccount',
    component: carAccount //城市车队用车明细列表
  },
  {
    path: '/cityProjectProcess',
    name: 'cityProjectProcess',
    component: projectProcess //城市车队进程列表
  }
];
//网约车路由
const platformRouters = [
  {
    path: '/addPlatform',
    name: 'addPlatform',
    component: addPlatform //填写預算
  },
  {
    path: '/budgetList',
    name: 'budgetList',
    component: budgetList //預算列表
  },
  {
    path: '/platformIndex',
    name: 'platformIndex',
    component: platformIndex, //
    meta: {
        keepAlive: SmartStorage.get('viewOnly'),
       }
    // meta: {
    //   keepAlive: true
    // }
  },
  {
    path: '/orderDetail',
    name: 'orderDetail',
    component: orderDetail //
  },
  {
    path: '/platformEditOrder',
    name: 'platformEditOrder',
    component: platformEditOrder, //
    meta: {
      keepAlive: true,
      isBack: false,
    },
    beforeEnter: (to, from, next) => {
      if (from.name == 'platformPriceCoupon' && !SmartStorage.get('editdataForm')) {
        to.meta.isBack = true;
        next();
      } else {
        to.meta.isBack = false;
        next();
      }
    }
  },
  {
    path: '/platformPriceCoupon',
    name: 'platformPriceCoupon',
    component: platformPriceCoupon,
  },
  {
    path: '/platformProjectProcess',
    name: 'platformProjectProcess',
    component: projectProcess //进程列表
  },
  {
    path: '/openauth',
    name: 'openauth',
    component: openauth //神州授权页面
  },
  {
    path: '/batchApproval',
    name: 'batchApproval',
    component: batchApproval //山德士批量审批页面
  },
  
  {
    path: '/approvalIndex',
    name: 'approvalIndex',
    component: approvalIndex //住友用车审批
  },
  {
    path: '/approvalList',
    name: 'approvalList',
    component: approvalList //住友用车审批列表
  },
  {
    path: '/approvalPassenger',
    name: 'approvalPassenger',
    component: approvalPassenger //住友用车审批列表
  },
  {
    path: '/approvalLog',
    name: 'approvalLog',
    component: approvalLog //住友用车审批列表日志
  },
  {
    path: '/allCarOrder',
    name: 'allCarOrder',
    component: allCarOrder, //默沙东所有订单列表
    meta: {
      keepAlive: true
    }
  },
  {
    path: '/onlineRental',
    name: 'onlineRental',
    component: onlineRental //线上租车
  },
  {
    path: '/onlineEditOrder',
    name: 'onlineEditOrder',
    component: onlineEditOrder //线上租车编辑
  },
  {
    path: '/onlinePlatformPriceCoupon',
    name: 'onlinePlatformPriceCoupon',
    component: onlinePlatformPriceCoupon //线上租车编辑
  }
];

const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes: platformRouters.concat(tenantRouters)
});
export default router;
