<template>
    <div class="i-add-button" @click="onClick" @touchmove="touchmove($event)">
      <svg class="icon" aria-hidden="true">
        <use xlink:href="#icon-tianjia3" />
      </svg>
    </div>
  </template>
  
  <script>
  export default {
    name: "AddButton",
    methods: {
      onClick() {
        this.$emit("click");
      },
      touchmove(event) {
        event.preventDefault(); //阻止默认事件传播
        const _circle = event.targetTouches[0];
        const _create_event = document.getElementsByClassName("i-add-button")[0];
        const _clientWidth = document.documentElement.clientWidth;
        const _clientHeight = document.documentElement.clientHeight;
  
        if (_circle.pageX > 28 && _circle.pageX < _clientWidth - 28) {
          _create_event.style.left = -28 + _circle.pageX + "px";
        }
        if (_circle.pageY > 118 && _circle.pageY < _clientHeight - 84) {
          _create_event.style.top = -28 + _circle.pageY + "px";
        }
      },
    },
  };
  </script>
  
  <style lang="scss">
  .i-add-button {
    cursor: pointer;
    position: fixed;
    right: 0.35rem;
    bottom: 1.3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 0.5rem;
    height: 0.5rem;
    background: var(--themeColor);
    border-radius: 50%;
   
    .icon{
        width: 0.3rem;
        height: 0.3rem;
        color: #fff;     
    }
  }
  </style>
  