import { getCookie } from 'tiny-cookie';
import { SmartStorage } from 'smart-core-util';
import iJsBridge from 'smart-javascript-bridge';
import  iAuthorization  from '@/plugins/authorization.js';
// import 'smart-ui/lib/index.css';

class iLogout {
  constructor() {
    this.iJsBridge = new iJsBridge({
      alert: e => {
        console.log(e);
      }
    });
  }
  setLoginUrl() {
    const rnd = new Date().getTime();
    const tenant = getCookie('tenant_code') || SmartStorage.get('tenant_code');
    const version = getCookie('version') || SmartStorage.get('version');
    const platform = SmartStorage.get('platform') || 'app';
    const Uris = SmartStorage.get('Uris').Uris || {};
    const container = getCookie('container');
    let urllogout = Uris['webapp-iaccount'] + '/?tenant=' + tenant + '&platform=' + platform + '&removeCookie=true' + '&srcVersion=68&rnd=' + rnd;
    if (version) {
      urllogout += `&version=${version}`;
    }
    if (container) {
      urllogout += `&container=${container}`;
    }
    return urllogout;
  }
  async logoutAsync() {
    const urllogout = this.setLoginUrl();
    const tenant = getCookie('tenant_code') || SmartStorage.get('tenant_code');
    const iEdition = getCookie('container');
    console.log('====本地退出配置', urllogout, iEdition);
    if (iEdition && (iEdition == 'h5' || iEdition == 'browser')) {
      
      iAuthorization.setiDomain();
      iAuthorization.deleteLocalData();
      setTimeout(() => {
        
        tenant=='roche'? window.close():window.open(urllogout, '_self');
      }, 200);
    } else {
      if (window.flutter_inappwebview) {
        await window.flutter_inappwebview.callHandler('App.clearAppData', '');
        this.clearCacheCallback(urllogout);
      } else {
        this.iJsBridge.call({
          method: 'SMGeneral.clearCache',
          callback: this.clearCacheCallback(urllogout)
        });
      }
    }
  }
  clearCacheCallback(urllogout) {
    // console.log('openWebappUrl=', urllogout);
    if (window.flutter_inappwebview) {
      let params = {
        url: urllogout
      };
      window.flutter_inappwebview.callHandler('SmGeneral.openNewWebSiteAndRemove', JSON.stringify(params));
    } else {
      this.iJsBridge.openWebapp({
        loadUrl: urllogout,
        ignoreCookie: true
      });
      this.iJsBridge.close();
    }
  }
}

export default iLogout;
