import { SmartStorage } from 'smart-core-util';
import { httpService } from '../services/httpService';
import * as tinyCookie from 'tiny-cookie';
var enviroment = process.env.NODE_ENV; // 线上
export default class AppInitialization {
  static install() {
    this.setiDomain();
    this.urlParamToStorage();
    return new Promise(function(resolve) {
      let tenant_id = tinyCookie.get('tenant_id') || SmartStorage.get('tenant_id');
      let tenant_code = tinyCookie.get('tenant') || SmartStorage.get('tenant') || SmartStorage.get('tenant_code');
      if (tenant_code == 'crossevent') {
        switch (tenant_id) {
          case '7faf5393-8a70-11ec-89d2-6a0616dfdee4':
            //石药
            tenant_code = 'crossevent_cspc';
            break;
          case '1afb2744-d3c9-11eb-a9f0-ca43ba5bd41d':
            //华润
            tenant_code = 'crossevent_cpc';
            break;
          default:
            tenant_code = 'crossevent';
            break;
        }
      }

      tinyCookie.get('version') && tinyCookie.get('version').includes('gray') && tinyCookie.set('gray', true);
      let params = {
        collection: 'cfg-ievent-initialization',
        filter: {
          container:tinyCookie.get('container')=='h5'?"h5":"",
          tenantId: tenant_id
        },
        projection: {
          _id: 0
        }
      };
      httpService
        .getsettings(params)
        .then(response => {
          console.log(response);
          if (response && response.success && response.content) {
            let config = response.content.config || {};
            AppInitialization.setTheme(config.themeColors);
            AppInitialization.setTitle(config.titleTxt);
            let isGray = SmartStorage.get('gray') ? config.grayUris : config.uris;
            AppInitialization.setUris(isGray);
          } else {
            AppInitialization.getBaseJson(tenant_code);
            // AppInitialization.setDefault()
          }

          resolve(response);
        })
        .catch(error => {
          AppInitialization.setDefault();
          resolve(error);
        });
    });
  }
  static getBaseJson(tenant_code) {
    return new Promise(function(resolve) {
    let base=SmartStorage.get('gray')?'base_v2.json':'base.json';
    let url = `https://spkg.smartmice.cn/configuration/iapp-config/${tenant_code}/${enviroment}/${base}`;
    let params = {
      params: {
        rnd: new Date().getTime()
      }
    };
    httpService
      .Get(url, params)
      .then(response => {
        console.log(typeof response);
        AppInitialization.setTheme(response.themeColors);
        AppInitialization.setTitle(response.titleTxt);
        resolve(response);
      })
      .catch(error => {
        AppInitialization.setDefault();
        resolve(error);
      });
    })
  }

  static setDefault() {
    console.log('set default');
    AppInitialization.setTheme([
      { attribute: '--themeColor', value: '#21BDB0' },
      { attribute: '--clickColor', value: '#08998e' },
      { attribute: '--opacityColor', value: '#16797029' },
      { attribute: '--gradualColor', value: '#0fa99d' },
      { attribute: '--shadowColor', value: '#029e9266' },
      { attribute: '--lineColor', value: '#027d71' }
    ]);
    AppInitialization.setTitle();
  }
  // 设置皮肤颜色
  static setTheme(colorList) {
    console.log('setTheme: ', colorList);
    colorList.map(item => {
      document.body.style.setProperty(item.attribute, item.value);
    });
  }
  // 设置title
  static setTitle(txt = '会引擎 - 会议平台') {
    document.title = txt;
  }

  // url 参数转 storage
  static urlParamToStorage() {
    let url = window.location.search.substring(1);
    if (url) {
      let urlParams = url.split('&');
      urlParams.forEach(item => {
        let temp = item.split('='); 
        if (temp[0] == 'extNum') {
          temp[1] = decodeURIComponent(temp[1]).split('?')[0];
        }
        SmartStorage.set(temp[0], temp[1]);
      });
      //不存在表明不是从ievent全部订单进入的，则storage中isAll为false；
      if(url.search('isAll')==-1){
        SmartStorage.set('isAll', false);
      }
      //住友区分是更多进入还是详情进入
      if(url.search('isFromMore')==-1&&SmartStorage.get('tenant_code')=='dsm'){
        SmartStorage.set('isFromMore', false);
      }
    }
  }

  static setiDomain() {
    let domain = document.domain;
    const re = /^(\d+)\.(\d+)\.(\d+)\.(\d+)$/; //正则表达式
    let isIP = re.test(domain);
    let isLocalhost = domain == 'localhost';
    console.log('document domain:', domain);
    domain = isIP || isLocalhost ? domain : domain.substring(domain.indexOf('.') + 1);
    window.idomain = domain;
  }
  // 设置Uris
  static setUris(uris = {}) {
    SmartStorage.set('Uris', { Uris: uris });
  }
}
