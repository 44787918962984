import { SmartHttp, SmartStorage } from 'smart-core-util';
import iLogout from '@/plugins/logout.js';
// 小程序上会人员特殊处理
let headers = {}
if(SmartStorage.get('container') == 'miniprogram' && SmartStorage.get('authMobile')){
  headers.authMobile = SmartStorage.get('authMobile')
}
const request = new SmartHttp({
  baseURL: process.env.VUE_APP_GATEWAY,
  // baseURL: "https://a1-gateway-test.roche.com.cn",
  // baseURL:"https://gateway.smartmice.cn",
  disableLoadingAnimation: false,
  timeout: 20000,
  headers: headers,
  handle401: () => {
    console.log('handle401', iLogout);
    const Logout = new iLogout();
    Logout.logoutAsync();
  }
}).request;

const httpService = {
  // get公共方法
  Get: (url, params) => {
    return request.get(url, params);
  },
  // tpm exists
  tpmExists: params => {
    return request.post('/api/tpm/cfg/exists', params);
  },
  // 数据持久化-获取
  queryCacheData: params => {
    return request.post('/api/statestore/redis/query', params);
  },
  /**
   * 公共post
   */
  common: (url, params, conf) => {
    return request.post(url, params || {}, conf || {});
  },
  /**
   * 公共tpm -getsettings
   */
  getsettings: params => {
    return request.post('/api/tpm/cfg/getsettings', params);
  },
  querysettings: params => {
    return request.post('/api/tpm/cfg/querysettings', params);
  },
  // 查询评分
  QuerySurvay: params => {
    return request.post(`/api/survey/Survay/QuerySurvay`, params);
  },
  // 保存评分
  SaveSurvay: params => {
    return request.post(`/api/survey/Survay/SaveSurvay`, params);
  },
  /**
   * 检测是否可以添加新需求
   */
  CheckCanNewItem: params => {
    return request.post('/api/category/car/Order/CheckCanNewItem', params);
  },
  /**
   * 城市订单分页查询
   */
  QueryEOrder: params => {
    return request.post('/api/eorder/Order/QueryEOrder', params);
  },
  /**
   * 城市车队供应商
   */
  pagingbyzone: params => {
    return request.post('/api/resource/car/supplier/pagingbyzone', params);
  },
  /**
   * 城市车队供应商详情
   */
  getdetail: params => {
    return request.post('/api/resource/car/supplier/getdetail', params);
  },
  /**
   * 城市车队用车形式
   */
  getcarstyles: params => {
    return request.post('/api/resource/car/suppliercar/getcarstyles', params);
  },
  /**
   * 城市车队机场选择
   */
  getzoneinfo: params => {
    return request.post('/api/resource/car/suppliercar/getzoneinfo', params);
  },
  /**
   * 城市车队车型
   */
  getcartypes: params => {
    return request.post('/api/resource/car/suppliercar/getcartypes', params);
  },
  /**
   * 城市车队聚合报价
   */
  GetQuoteds: params => {
    return request.post('/api/resource/car/suppliercar/GetQuoteds', params);
  },
  /**
   * 城市车队供应商详情
   */
  getdetailCity: params => {
    return request.post('/api/resource/car/supplier/getdetail', params);
  },
  /**
   * 用车形式
   */
  GetSupplierDict: params => {
    return request.post('/api/resource/car/Supplier/GetSupplierDict', params);
  },
  /**
   * 城市车队获取预估价格
   */
  GetPrice: params => {
    return request.post('/api/resource/car/Supplier/GetPrice', params);
  },
  /**
   * 城市车队保存需求
   */

  SaveItem: params => {
    return request.post('/api/category/car/Order/SaveItem', params);
  },
  /**
   * 删除需求
   */
  DeleteItem: params => {
    return request.post('/api/category/car/Order/DeleteItem', params);
  },
  /**
   * 城市车队需求列表
   */
  QueryItems: params => {
    return request.post('/api/category/car/Order/QueryItems', params);
  },
  /**
   * 获取订单详情
   */
  GetEOrder: params => {
    return request.post('/api/car/eorder/Order/GetEOrder', params);
  },
  /**
   * 发起询价
   */
  SubmitAllItem: params => {
    return request.post('/api/category/car/Order/SubmitAllItem', params);
  },
  /**
   * 获取订单按钮
   */
  QueryTaskActions: params => {
    return request.post('/api/foundation/bpm/foundation/workflow/v2/Task/QueryTaskActions', params);
  },
  /**
   * 订单按钮操作
   */
  PerformTask: params => {
    return request.post('/api/foundation/bpm/foundation/workflow/v2/Task/PerformTask', params);
  },

  /**
   * 网约车订单列表
   */
  QueryOnlineOrder: params => {
    return request.post('/api/category/car/Order/QueryOnlineOrder', params);
  },
  /**
   * 网约车获取用车形式
   */
  GetCarService: params => {
    return request.post('/api/category/car/OnlineCar/GetCarService', params);
  },
  /**
   * 网约车获取机场
   */
  GetCityAirports: params => {
    return request.post('/api/category/car/OnlineCar/GetCityAirports', params);
  },

  /**
   * 获取授权Url
   */
  GetUserToken: params => {
    return request.get(`/api/category/car/Order/GetUserToken?sourceCode=${params.sourceCode}&ran=${params.getRandomNum}`);
  },
  /**
   * 获取用户token
   */
  GetTokenUrl: params => {
    return request.get('/api/category/car/Order/GetTokenUrl', {
      params: params,
      headers: {
        HostName: window.location.hostname
      }
    });
  },
  /**
   * 聚合线上车查询报价接口
   */
  GetPriceCoupon: params => {
    return request.post('/api/category/car/OnlineCar/GetPriceCoupon', params);
  },
  /**
   * 聚合线上车查询报价接口2
   */
  GetPriceCouponStream: params => {
    return request.post('/api/category/car/OnlineCar/GetPriceCouponStream', params);
  },
  /**
   * 下单
   */
  CreateOnlineOrder: params => {
    // request.changeAutoExtractResponseError(false);
    return request.post('/api/category/car/Order/CreateOnlineOrder', params);
  },
  /**
   * u+
   */
  SetUserPersonalizations: params => {
    return request.post('/api/category/car/CarSeting/SetUserPersonalizations', params);
  },
  //获取订单详情
  GetOnlineDetail: params => {
    return request.post('/api/category/car/OnlineCar/GetOnlineDetail', params);
  },
  //获取滴滴安全按钮
  GetSafetyConvoy: params => {
    return request.post('/api/category/car/Order/GetSafetyConvoy', params);
  },
  //获取滴滴分享按钮
  GetTripShareDetail: params => {
    return request.post('/api/category/car/Order/GetTripShareDetail', params);
  },
  //打开滴滴h5
  ESWebappUrl: params => {
    return request.post('/api/category/car/DiDiCar/ESWebappUrl', params);
  },
  //打开滴滴h5
  ESWebappCarMergeUrl: params => {
    return request.post('/api/category/car/DiDiCar/ESWebappCarMergeUrl', params);
  },
  //打开高德h5
  GetOrderUrl: params => {
    return request.post('/api/category/car/GaoDeCar/GetOrderUrl', params);
  },
  //一键叫车保存需求
  SaveItemOrders: params => {
    return request.post('/api/category/car/Order/SaveItemOrders', params);
  },
  //一键叫车下单
  CreateBatchOrder: params => {
    return request.post('/api/category/car/Order/CreateBatchOrder', params);
  },
  //刷新高德订单
  Refresh: params => {
    return request.post('/api/category/car/GaoDeCar/Refresh', params);
  },
  //刷新订单
  RefreshOrder: params => {
    return request.post('/api/category/car/Order/RefreshOrder', params);
  },
  //修改目的地
  UpdateEndAddress: params => {
    return request.post('/api/category/car/Order/ModifyDestination', params);
  },
  //取消订单
  CancelOnlineOrder: params => {
    return request.post('/api/category/car/Order/CancelOnlineOrder', params);
  },
  /**
   * 字典查询
   */
  queryDict: params => {
    return request.post('/foundation/dict/api/FnDictionary/Query', params);
  },
  /**
   * 进程
   */
  QueryOrders: params => {
    return request.post('/api/category/car/OfflineCar/QueryOrders', params);
  },
  /**
   * 进程
   */
  AppointTaskLogs: params => {
    return request.post('/api/foundation/bpm/foundation/workflow/v2/Task/AppointTaskLogs', params);
  },
  getAppInitialization: (url, params) => {
    return request.get(url, params);
  },
  /**
   * 订单列表
   */
  Paging: params => {
    return request.post('/api/car/eorder/xorder/Paging', params);
  },
  /**
   * 订单状态列表
   */
  StatByStatus: params => {
    return request.post('/api/car/eorder/xorder/StatByStatus', params);
  },
  /**
   * 完成订单评分按钮
   */
  GetExtraBtns: params => {
    return request.post('/api/survey/Survay/GetExtraBtns', params);
  },
  /**
   * 住友审批
   */
  dsm_StatByStatus: params => {
    return request.post('/api/tenants/dsm/ApproveOrder/StatByStatus', params);
  },
  /**
   * 住友审批订单列表
   */
  dsm_Paging: params => {
    return request.post('/api/tenants/dsm/ApproveOrder/Paging', params);
  },
  //保存实际乘车人
  SavePassenger: params => {
    return request.post('/api/category/car/Order/SavePassenger', params);
  },
  //查询实际乘车人
  QueryPassenger: params => {
    return request.post('/api/category/car/Order/QueryPassenger', params);
  },
  //审批日志
  QueryAccessTaskLogs: params => {
    return request.post('/api/foundation/bpm/foundation/workflow/v2/Task/QueryAccessTaskLogs', params);
  },
  //后端代码不能更新TaskId需要前端手动获取
  GetCurrentTaskId: params => {
    return request.post('/api/foundation/bpm/foundation/workflow/v2/Task/GetCurrentTaskId', params);
  },
  //住友常用联系人
  GetAllContacts: params => {
    return request.post('/api/category/car/Order/GetAllContacts', params);
  },
  //内部参会人列表
  InPagingByMarker: params => {
    return request.post('/api/tenants/roche/Employee/PagingByMarker', params);
  },
  //外部参会人列表
  OutPagingByMarker: params => {
    return request.post('/api/tenants/roche/HcpInfo/PagingByMarker', params);
  },
  //常用参会人列表
  QueryOftenAttender: params => {
    return request.get(`/api/engine/event/UserOftenAttender/QueryOftenAttender?miceId=${params.miceId}`);
  },
  //3参会人列表
  QueryMiceAttendersFromCarPassenger: params => {
    return request.post('/api/tenants/roche/Attender/QueryMiceAttendersFromCarPassenger', params);
  },
  SaveGaoDeItem: params => {
    return request.post('/api/category/car/Order/SaveGaoDeItem', params);
  },
  //获取用户职位
  getEmployee: params => {
    return request.post('/api/tenants/dsm/Employee/Get', params);
  },
  //获取所有订单
  MergePaging: params => {
    return request.post('/api/car/eorder/xorder/MergePaging', params);
  },
  //保存订单说明
  SaveUseCarRemark: params => {
    return request.post('/api/category/car/Order/SaveUseCarRemark', params);
  },
  //保存提示语
  SaveCarProtocol: params => {
    return request.post('/api/category/car/Order/SaveCarProtocol', params);
  },
  //获取提示语
  GetCarProtocol: params => {
    return request.post('/api/category/car/Order/GetCarProtocol', params);
  },

  //线上租车用车形式
  GetCarRules: params => {
    return request.post('/api/category/citycar/Order/GetCarRules', params);
  },
  //获取线上租车订单详情
  GetEstimatePrice: params => {
    return request.post('/api/category/citycar/Order/GetEstimatePrice', params);
  },
  //线上租车保存需求
  OnlineSaveItem: params => {
    return request.post('/api/category/citycar/Order/SaveItem', params);
  },
  //线上租车提交订单
  OnlineSubmitItem: params => {
    return request.post('/api/category/citycar/Order/SubmitItem', params);
  },
  //线上租车删除订单
  OnlineDeleteItem: params => {
    return request.post('/api/category/citycar/Order/DeleteItem', params);
  },
  //线上租车取消订单
  CancelItem: params => {
    return request.post('/api/category/citycar/Order/CancelItem', params);
  },
  //线上租车详情
  GetOrderDetail: params => {
    return request.post('/api/category/citycar/Order/GetOrderDetail', params);
  },
  //线上租车刷新订单
  OnlineRefreshOrder: params => {
    return request.post('/api/category/citycar/Order/RefreshOrder', params);
  },
  //线上租车行车轨迹
  GetOrderLocation: params => {
    return request.post('/api/category/citycar/Order/GetOrderLocation', params);
  },
  //线上租车获取机场
  OnlineGetOrderLocation: params => {
    return request.post('/api/category/citycar/Order/GetCityAirports', params);
  },
  //线上租车修改目的地
  OnlineUpdateEndAddress: params => {
    return request.post('/api/category/citycar/Order/ModifyDestination', params);
  },
  //线上租车确认订单
  OnlineConfirmOrder: params => {
    return request.post('/api/category/citycar/Order/ConfirmOrder', params);
  },
  //修改实际人数
  UpdateItemRealPerson: params => {
    return request.post('/api/category/car/Order/UpdateItemRealPerson', params);
  },
  //获取行车轨迹图片
  getOssQuery: params => {
    return request.post('/foundation/aliyun/api/oss/query', params);
  },
  // // 数据持久化-存储
  // saveCacheData: params => {
  //   return request.post('/api/statestore/redis/create', params);
  // },
  saveCacheData: params => {
    return request.post('/xchief/api/xcache/create', params);
  },
  //山德士 获取审批列表
  QueryByUser: params => {
    return request.post('/api/category/car/mergecar/trips/QueryByUser', params);
  },
  //山德士 批量审批
  BatchPerformTask: params => {
    return request.post('/api/foundation/bpm/foundation/workflow/v2/Task/BatchPerformTask', params);
  },
  //保险需求 获取司机信息
  currentDrivers: params => {
    return request.post('/api/car/eorder/Order/CurrentDrivers', params);
  },
  //山德士预估报价会议用车卡控
  ExecuteDeciderDapr: params => {
    return request.post('/api/tpm/cfg/ExecuteDeciderDapr', params);
  }
};
export { request, httpService };
