import Vue from 'vue';
import { httpService } from '../../services/httpService';
import { SmartStorage } from 'smart-core-util';
let wx = window.wx
const nativeJsBridge = {
  data() {
    return {
      navBackgroundColor: {
        lilly: '#D7290F',
        roche: '#2e82ef',
        novartis: '#0099FF',
        msd: '#00857c',
        pfizer: '#E60012',
        viatris: '#E60012',
        dsm: '#00A16D',
        ipsen: '#224a81',
        abbvie: '#071d49'
      }
    };
  },
  created() {
    if (!window.webkit && window.SMGeneral) {
      window.SMGeneral.setBackPressEnable(true);
      window.SMGeneral.setOnBackBressedListener('callBack');
    }
    window['callBack'] = () => {
      this.nativeBackJsBridge();
    };
  },
  methods: {
    addOnResumeEvent() {
      if (window.flutter_inappwebview) {
        window.flutter_inappwebview.callHandler('App.addLifeCycleJs', 'fluttercallBack');
      } else {
        var options = {
          loadUrl: '',
          method: 'SMGeneral.addOnResumeEvent',
          postData: '',
          callback: () => {
            this.installSingler();
            this.MapLoaderM();
            // if(hasOpenSignal)return;
            // hasOpenSignal = true;
            // this.openSignal()
          }
        };
        this.iJsBridge.call(options);
      }
    },
    removeOnResumeEvent() {
      if (window.flutter_inappwebview) {
        window.flutter_inappwebview.callHandler('App.removeLifeCycleJs');
      } else {
        var options = {
          loadUrl: '',
          method: 'SMGeneral.removeOnResumeEvent',
          postData: '',
          callback: () => {
            // if(hasOpenSignal)return;
            // hasOpenSignal = true;
            // this.openSignal()
          }
        };
        this.iJsBridge.call(options);
      }
    },
    async openNewWebSiteForResult(postData, callback, type) {
      if (window.flutter_inappwebview) {
        if (type == 'isDIDI') {
          postData.didiCovering = {
            showLeftBtn: true
          };
        }
        let params = {
          url: postData.loadUrl,
          postData: postData,
          cookies: {}
        };
        let result = await window.flutter_inappwebview.callHandler('SmGeneral.openNewWebSite', JSON.stringify(params));
        if (callback) {
          callback(result);
        }
      } else {
        const options = {
          method: 'SMGeneral.openNewWebSiteForResult',
          postData: postData,
          callback: callback
        };
        this.iJsBridge.call(options);
      }
    },
    //打开h5页面
    gosafetyCenter(type, url, callBack, arry) {
      let tenant_code = this.$cookies.get('tenant') || 'roche';
      let bgColor = document.body.style.getPropertyValue('--themeColor').trim();
      const postData = {
        loadUrl: url,
        browserMode: {
          showDiDiCovering: type == 'isDIDI',
          didiShareCheckList: type == 'isDIDI' && arry,
          finishUrlCheckArray: arry,
          navBarVisibilty: type != 'isDIDI',
          titleTextColor: tenant_code == 'pfizer' ? bgColor : '#ffffff',
          navBackgroundColor: bgColor,
          didiCovering: null
        }
      };
      this.openNewWebSiteForResult(postData, callBack, type);
    },
    nativeBackJsBridge() {
      let routeListeners = this.SmartStorage.get('routeListeners');
      if (routeListeners.length > 0) {
        var key = routeListeners.pop();
        //				Toast(key)
        //改用bus广播与监听TODO
        switch (key) {
          case 'closeMintUIMessageBox':
            this.closeMintUIMessageBox();
            break;
          case 'closeVuxDatetime':
            this.closeVuxDatetime();
            break;
          default:
            this.$root.$eventHub.$emit(key);
        }
        //mixin 通过外部js调用组件内部方法会出现 Toast(key + 'is undefined')情况TODO
        //				switch(this.hasOwnProperty(key)) {
        //					case true:
        //						this[key]();
        //						break;
        //					case false:
        //						Toast(key + 'is undefined');
        //						break;
        //				}
      }
    },
    //保存当前路由返回事件记录
    setRouteListeners(backMethod) {
      let routeListeners = this.SmartStorage.get('routeListeners');
      if (!this.SmartStorage.get('isshowtitle') && routeListeners.indexOf(backMethod) == -1) {
        routeListeners.push(backMethod);
      }
      this.SmartStorage.set('routeListeners', routeListeners);
    },
    //返回上一页删除当前退回记录
    removeRouteListeners(backMethod) {
      let routeListeners = this.SmartStorage.get('routeListeners');
      routeListeners = routeListeners.filter(ele => {
        return ele != backMethod;
      });
      this.SmartStorage.set('routeListeners', routeListeners);
    },
    //安卓返回键，关闭mintUI <MessageBox>
    closeMintUIMessageBox() {
      setTimeout(() => {
        if (document.getElementsByClassName('mint-msgbox-cancel')) {
          document.getElementsByClassName('mint-msgbox-cancel')[0].click();
          this.removeRouteListeners('closeMintUIMessageBox');
        } else {
          this.removeRouteListeners('closeMintUIMessageBox');
        }
      });
    },
    //安卓返回键，关闭Vux <datePicker>
    closeVuxDatetime() {
      setTimeout(() => {
        if ([...document.getElementsByClassName('vux-datetime-cancel')].length > 0) {
          document.getElementsByClassName('vux-datetime-cancel')[0].click();
        }
      });
    },
    async getActionEdit(tenantCode) {
      let params = {
        collection: 'cfg-code-anchor',
        useCaching: false,
        cachingKey: '',
        filter: {
          Anchor: 'Appx-Base-All-Action-Eidt-Disable',
          Tenants: {
            $in: [tenantCode]
          }
        }
      };
      await this.services.tpmExists(params).then(function(res) {
        if (res.success) {
          return res.content;
        }
      });
    },
    async getUrl() {
      let miceId = this.SmartStorage.get('proposalId');
      let requireId = this.SmartStorage.get('eventData').requireId;
      const eventData = this.SmartStorage.get('eventData');
      const ieventUrl = this.SmartStorage.get('Uris').Uris['webapp-ievent'];
      let ievent = '';
      let tenant_code = this.SmartStorage.get('tenant') || this.SmartStorage.get('tenant_code') || eventData.tenant_code;

      const sessionId = await this.saveCacheData();
      this.SmartStorage.set('sessionId', sessionId);

      switch (tenant_code) {
        case 'crossevent':
        case 'novartis':
        case 'pfizer':
          ievent = eventData.miceId
            ? `${ieventUrl}/eventDetail?miceId=${eventData.miceId}&sessionId=${this.SmartStorage.get('sessionId')}`
            : `${ieventUrl}/application?&sessionId=${this.SmartStorage.get('sessionId')}`;
          break;
        case 'msd':
          ievent = this.SmartStorage.get('isAll')
            ? `${ieventUrl}/?sessionId=${this.SmartStorage.get('sessionId')}`
            : `${ieventUrl}/eventDetail?miceId=${miceId}&sessionId=${this.SmartStorage.get('sessionId')}&businessId=${miceId}&processId=${miceId}`;
          break;
        case 'dsm':
          ievent = this.SmartStorage.get('isFromMore')
            ? `${ieventUrl}/application?sessionId=${this.SmartStorage.get('sessionId')}`
            : `${ieventUrl}/eventDetail?miceId=${miceId}&sessionId=${this.SmartStorage.get('sessionId')}`;
          break;
        case 'roche':
          ievent = (eventData.isFromMore||this.SmartStorage.get('isFromMore'))
          ?`${ieventUrl}${eventData.returnRouterPath}?miceId=${miceId}&rad=${this.SmartStorage.get('sessionId')}`
          :`${ieventUrl}/eventDetail?miceId=${miceId}&rad=${this.SmartStorage.get('sessionId')}`;
          break;
        default:
          ievent = `${ieventUrl}/opConfirmPrice?requireId=${requireId}&miceId=${miceId}&sessionId=${this.SmartStorage.get('sessionId')}`;
          break;
      }
      return ievent;
    },
    // h5模式
    async _h5Back() {
      if(SmartStorage.get('miniprogram')){
        wx.miniProgram.redirectTo({
            url: '/pages/eventDetail'
        })
        return
      }
      let url = await this.getUrl();
      console.log('h5Back===', url);
      window.open(url, '_self');
    },
    async _browserBack() {
      let url = await this.getUrl();
      console.log('browserBack===', url);
      let aTag = document.createElement('a');
      aTag.href = url;
      aTag.click();
      // window.open(url);
    },
    closeAppWithReload() {
      var sendData = JSON.stringify({ handle: 'reload_event_detail' });
      if (window.flutter_inappwebview) {
        window.flutter_inappwebview.callHandler('SmGeneral.closeWebSite', sendData);
      } else {
        this.iJsBridge.call({
          method: 'SMGeneral.setResultToNative',
          postData: sendData
        });
        this.iJsBridge.close();
      }
    },
    async saveCacheData() {
      const params = {
        //内容
        Data: {
          // 用户信息
          supplierId: this.SmartStorage.get('supplierId') || this.$cookies.get('supplierId'),
          userId: this.SmartStorage.get('userId') || this.$cookies.get('userId'),
          role_codes: this.SmartStorage.get('role_codes') || this.$cookies.get('role_codes'),
          directory_id: this.SmartStorage.get('directory_id') || this.$cookies.get('directory_id'),
          tenant_code: this.SmartStorage.get('tenant_code') || this.$cookies.get('tenant_code'),
          token: this.SmartStorage.get('token') || this.$cookies.get('token'),
          container: this.SmartStorage.get('container') || this.$cookies.get('container'),
          tenant_id: this.SmartStorage.get('tenant_id') || this.$cookies.get('tenant_id'),
          platform: this.SmartStorage.get('platform') || this.$cookies.get('platform')
        },
        Strategy:"Session"
        // //过期策略
        // CacheEntryOptions: {
        //   AbsoluteExpirationRelativeToNow: '08:00:00' //从现在开始计时(时:分:秒）过期
        // },
        // Prefix: 'jwts_'
      };
      const res = await httpService.saveCacheData(params);
      return res.content || '';
    }
  }
};
Vue.mixin(nativeJsBridge);
export default nativeJsBridge;
