import { SmartStorage } from 'smart-core-util'
import { httpService } from '../services/httpService'
import { setCookie,getCookie } from 'tiny-cookie'
export default class CacheData {
	static install() {
        let url = window.location.search.substring(1);
        console.log('CacheData==');
        let urlParamsObj = {};
        if(url){
            let urlParams = url.split('&');
            urlParams.forEach(item => {
                let temp = item.split('=');
                urlParamsObj[temp[0]] = temp[1];
            })
        } 
        if(!urlParamsObj.sessionId) return;
        if(urlParamsObj.authMobile){
            SmartStorage.set('authMobile',urlParamsObj.authMobile);
        }
		return new Promise(function (resolve) {
            let params = {
                "sessionId": urlParamsObj.sessionId || ""
            }

            httpService.queryCacheData(params).then(response => {
                CacheData.setData(response.content)

                resolve(response)
            }).catch(error => {
                resolve(error)
            })
        })
    }
    // 设置缓存数据
    static setData(data) {
        console.log('data==',data);
        
        // let objKeyList = Object.keys(d);

        
        // if(d.auth){
        //     setCookie("token", d.auth.access_token);
        //     setCookie("tenant", d.auth.tenant_code);
        //     // setCookie("tenantId", d.auth.tenantId);
        //     setCookie("tenant_id", d.auth.tenant_id);
        //     // setCookie("eventData", d.eventData);
        //     let objKeyList = Object.keys(d.data);
        //     objKeyList.map(item => {
        //         console.log(item)
        //         console.log(d.data[item])
        //         SmartStorage.set(item, d.data[item]);
        //     });
        //     SmartStorage.set("eventData", d.eventData)
        //     SmartStorage.set("isH5", d.isH5)
        //     SmartStorage.set("proposalId", d.data.eventData.proposalId);
        // }else{
        //     objKeyList.map(item => {
        //         SmartStorage.set(item, d[item]);
        //     });
        // }   
        let objKeyList = Object.keys(data);

        objKeyList.map(item => {
            // console.log("item==",item)
            // 兼容web端扫描二维码进入微站的数据
            if(['data','auth'].includes(item)) {
                let keys = Object.keys(data[item])
                keys.map(val => {
                    SmartStorage.set(val == "access_token" ? 'token' : val, data[item][val]);
                })
            } else {
                SmartStorage.set(item == "access_token" ? 'token' : item, data[item]);
            }
        });
        SmartStorage.set("proposalId",data.eventData.proposalId);
        console.log('CacheData111==');
        CacheData.setCooikes(data)
    }
    static setCooikes(response) {
        console.log('response===',response)
        let setting = {
          token: "token",
          userId: "userId",
          tenant_code: "tenant_code",
          tenant: "tenant_code",
          tenant_id: "tenant_id",
          role_codes: "role_codes",
          directory_id: "directory_id",
          supplierId: "supplierId",
          container:"container",
          platform:"platform"
        };
        // 获取过期时间
        let expiresTimes = "31104000s";
        if (response.container == "h5") {
          expiresTimes = "Session";
        }
        // cookie不到顶域
        // let config = { expires: expiresTimes, domain: window.idomain };
        let config = { expires: expiresTimes };
        CacheData.appendCookie(response, setting, config);
      }
     // 添加到 cookie(注意：因为涉及多个站点共享cookie问题，所以需要设置主域名相同)
    static appendCookie(data, setting, config) {
    setCookie('token', data.access_token||data.token);
    // SmartStorage.set('token', data.access_token||data.token);
    window.sessionStorage.setItem("token",data.access_token||data.token); 
    console.log('setting==',setting);
    for (var i in setting) {
     i!='token'&&setCookie(i, data[setting[i]], config)&&SmartStorage.set(i, data[setting[i]]);
    }
    
  }
}