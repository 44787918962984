<template>
  <div style="height: .39rem">
    <div
      :style="headStyle"
      :class="{
        headContent: backType == 'gra',
        headContent3: backType == 'blank',
        headContent2: backType == 'normal' || !backType,
      }"
    >
      <Svgs v-if="back && !device" :style="backStyle" @click.native="backPath" class="backBtn" :color="svgColor || setSvgColor" name="iconfanhui"></Svgs>
      <slot name="backTxt" v-if="device">
        <span class="backTxt" @click="backPath">返回</span>
      </slot>
      <!-- 标题 -->
      <slot name="headText"></slot>
      <!-- 右侧文字 -->
      <div class="rightBtn">
        <slot name="rightBtn"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'iHeader',
  props: [
    'back', //是否有后退按钮 默认无
    'backTxt', //是否有后退文字 默认无
    'backEvent', //是否自定义后退按钮事件 须配合@back-event使用
    'backType', //头部类型gra(配合渐变色) blank(白底黑字) normal或不填(正常填充色白字) white (白背景)
    'backStyle', //自定义按钮样式
    'headStyle', //自定义头部样式
    'svgColor' //特殊指定后退颜色
  ],
  computed: {
    device() {
      var ua = navigator.userAgent.toLowerCase();
      return ua.indexOf('micromessenger') != -1;
      // return true
    }
  },
  methods: {
    backPath() {
      if (this.backEvent) {
        this.$emit('back-event');
      } else {
        this.$router.go(-1);
      }
    },
    setSvgColor() {
      let color = '#000';
      if (this.backType == 'blank' || this.backType == 'white' || this.backType == 'gra') {
        color = '#fff';
      }
      return color;
    }
  }
};
</script>

<style lang="scss" scoped>
.headContent,
.headContent2,
.headContent3,
.headContent4 {
  height: 0.4rem;
  line-height: 0.4rem;
  font-size: 0.16rem;
  z-index: 100;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.headContent {
  background: var(--opacityColor) !important;
  color: #333 !important;
}

.headContent2 {
  background: rgba(0, 0, 0, 0);
  color: #333;
}

.headContent3 {
  background: var(--themeColor);
  color: #fff;
}

.headContent4 {
  background: #fff !important;
  color: #333 !important;
}
.backTxt {
  font-size: 0.15rem;
  width: 0.7rem;
}
.backBtn {
  width: 0.6rem;
}
.rightBtn {
  width: 0.7rem;
  div{
    font-size: 0.15rem;
  }
}
</style>
