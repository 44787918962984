/**
 * global-filter
 */
export default{
    /**
     * 格式化金额
     * @param {number} amount 金额
     * @param {string} [symbol] 货币符号
     * @param {number} [digits] 小数位数
     * @returns {string} 格式化后的数字，默认两位小数
     */
    currencyFormatter(amount, symbol = '￥', digits = 2) {
        return `${symbol}${Number(amount).toFixed(digits)}`
    },

    /**
     * 将手机号中间4位数字替换为星号
     * @param {string} str - 待处理字符串
     * @returns {string} 已处理字符串
     */
    phoneNumberStar(str) {
        str = String(str)
        return `${str.substr(0, 3)}****${str.substr(-4, 4)}`
    },
    // 格式化日期
    dataFormat(str){
        let s=str&&str.replace('T', ' ');
        return s
    },
    // 格式化日期2
    dataFormat2(str){
        let s=str.substring(0, 10);
        return s
    },
    /**
     * 格式化日期
     * @param {*string} date 待处理日期
     * @param {*string} fmt 期望日期格式如： yy/mm/dd
     * @returns {string} fmt 已处理日期字符串
     */
    formatDate(date, fmt) {
        if(!date){
            return
        }else{
            let newDate = new Date(date)
            if (/(y+)/.test(fmt)) {
                fmt = fmt.replace(RegExp.$1, (newDate.getFullYear() + '').substr(4 - RegExp.$1.length));
            }
            var o = {
                'M+': newDate.getMonth() + 1,
                'd+': newDate.getDate(),
                'h+': newDate.getHours(),
                'm+': newDate.getMinutes(),
                's+': newDate.getSeconds()
            };
            for (var k in o) {
                if (new RegExp(`(${k})`).test(fmt)) {
                    var str = o[k] + '';
                    fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? str : ('00' + str).substr(str.length));
                }
            }
            return fmt;
        }
    },

}
