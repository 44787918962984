import { SmartStorage } from 'smart-core-util'
import { httpService } from '../services/httpService'
import * as tinyCookie from 'tiny-cookie'
export default class GetApiVersion {

	static install() {
		return new Promise(function (resolve) {
			let params = {
				collection: "cfg-api-version",
				filter: {
					platform: "app",
					type: "icar",
					tenantCode: tinyCookie.get('tenant'),
					userIds: {
						$in: [tinyCookie.get("userId"), "*"]
					}
				},
				projection: {
					apiVersion: 1,
					userIds: 1
				},
				sort: {
					sort: 1
				}
			}
			if (SmartStorage.get('proposalId') == 'undefined' || SmartStorage.get('proposalId') == undefined) {
				SmartStorage.remove("proposalId");
			}
			let viewOnly = SmartStorage.get('proposalId') && SmartStorage.get('proposalId') != 'undefined' ? false : true
			let disableAllEdit=SmartStorage.get('disableAllEdit');
			!SmartStorage.get('eventData')&&SmartStorage.set('eventData',JSON.parse(tinyCookie.getCookie('eventData')))
			SmartStorage.set('viewOnly', disableAllEdit?disableAllEdit:viewOnly);
			["h5","browser"].includes(tinyCookie.get('container'))&&SmartStorage.set('isH5',true)
			httpService.getsettings(params).then(response => {
				GetApiVersion.setApiVersion(response.content.apiVersion || {})

				resolve(response)
			}).catch(error => {
				resolve(error)
			})
		})
	}
	// 设置apiVersion
	static setApiVersion(apiVersion = {}) {
		SmartStorage.set("apiVersion", apiVersion)
	}
}