import SetUris from '@/plugins/set-uris.js';
import AppInitialization from '@/plugins/app-initialization.js';
import GetApiVersion from '@/plugins/getApiVersion.js';
import CacheData from '@/plugins/cache-data.js';
import getTenantIds from '@/plugins/getTenantIds.js';
import Vue from 'vue';
import App from './App.vue';
import '@/assets/public.scss'; // 样式入口

import router from './router';
import store from './store';
import '@babel/polyfill';
// ARMS监控
import '@/plugins/alife-logger.js';

import { SmartRem, SmartStorage } from 'smart-core-util';
import 'smart-core-util/lib/index.css';
import { fixIOSInput, iDelay, iHtml } from './core';
// 引入rem布局
SmartRem.install();
Vue.use(fixIOSInput);
import nativeJsBridge from '@/views/controllers/nativeJsBridge.js';
import iSignalr from '@/views/controllers/smart-signalr';

import { request, httpService } from './services/httpService';
// import Mint from 'mint-ui';

// Vue.use(Mint);
import { Popup } from 'mint-ui';
Vue.component(Popup.name, Popup);

//引入vant 小数点键盘
import { Field } from 'vant';
Vue.use(Field);

import * as tinyCookie from 'tiny-cookie';
import iJsBridge from 'smart-javascript-bridge';

import validate from '@/views/controllers/validate';
import 'smartx-ui/lib/index.css';
import smartUI from 'smartx-ui';
Vue.use(smartUI);
import _ from 'lodash';
Vue.prototype._ = _;
import VueTouch from 'vue-touch';
Vue.use(VueTouch, { name: 'v-touch' });

import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
Vue.use(Viewer)

import '@/views/controllers/filter/filter'; // 全局filter
import publicFun from './services/publicFun';
//iToast
import {iToast} from '@/icomponents/iToast/plugin.js';
//iPopup
// import iPopup from "@/icomponents/iPopup/plugin.js";

// import SmartModule from 'smart-module';
// import 'smart-module/lib/index.css'
// Vue.use(SmartModule);

// 注册全局组件
import * as Components from './global-components';

/**
 * todo
 * 设置cookie - domin
 */
tinyCookie.domain = window.location.hostname;

Object.entries(Components).forEach(([key, component]) => {
  Vue.component(key, component);
});

Vue.prototype.iSignalr = iSignalr;
Vue.prototype.validate = validate;
Vue.prototype.services = httpService;
Vue.prototype.request = request;
Vue.prototype.ihtml = iHtml;
Vue.prototype.$publicFun = publicFun;
Vue.prototype.SmartStorage = SmartStorage;
Vue.prototype.$iDelay = iDelay;
Vue.prototype.$eventHub = Vue.prototype.$eventHub || new Vue();
Vue.prototype.$bus = Vue.prototype.$bus || new Vue();
Vue.prototype.$cookies = tinyCookie;
Vue.prototype.iJsBridge = new iJsBridge({
  alert: iToast
});
// Vue.prototype.$iPopup = iPopup;
Vue.prototype.$iToast = iToast;

Vue.config.productionTip = false;
Promise.resolve()
  .then(() => {
    return CacheData.install();
  })
  .then(() => {
    return getTenantIds.install();
  })
  .then(() => {
    return AppInitialization.install();
  })
  //   .then(() => {
  //     return SetUris.install();
  //   })
  .then(() => {
    return GetApiVersion.install();
  })
  .then(() => {
    new Vue({
      router,
      store,
      render: h => h(App)
    }).$mount('#app');
  });
  // ====
// Promise.all([ CacheData.install(),AppInitialization.install(), SetUris.install(), GetApiVersion.install()]).then(() => {
//     new Vue({
//         router,
//         store,
//         render: (h) => h(App),
//     }).$mount('#app')
// });
