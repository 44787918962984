<template>
  <div id="app">
    <!-- <router-view /> -->
    <!-- <template v-if="SmartStorage.get('viewOnly')"> -->
      <keep-alive>
        <!-- 需要缓存的视图组件 -->
        <router-view v-if="$route.meta.keepAlive"></router-view>
      </keep-alive>
      <!-- 不需要缓存的视图组件 -->
      <router-view v-if="!$route.meta.keepAlive"></router-view>
    <!-- </template> -->
    <!-- <router-view v-else /> -->
  </div>
</template>

<script>
export default {
  name: "App",
  mounted() {
    // this.SmartStorage.set(
    //   "_screen_height",
    //   document.documentElement.clientHeight || window.innerHeight
    // );
    // var lastTouchEnd = 0;
    // document.addEventListener("touchstart", function (event) {
    //   if (event.touches.length > 1) {
    //     event.preventDefault();
    //   }
    // });
    // document.addEventListener(
    //   "touchend",
    //   function (event) {
    //     var now = new Date().getTime();
    //     if (now - lastTouchEnd <= 300) {
    //       event.preventDefault();
    //     }
    //     lastTouchEnd = now;
    //   },
    //   false
    // );

    // // 阻止双指放大
    // document.addEventListener("gesturestart", function (event) {
    //   event.preventDefault();
    // });
  },
  components: {},
};
</script>

<style>
html, body {
  -webkit-overflow-scroll: touch;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
