/*
 * @Descripttion: 
 * @version: 1.0.0
 * @Author: Aggie
 * @Date: 2023-10-13 14:44:13
 */
// import _uris from './uris.json';
// const _uris = require('./uris.json');
import { getCookie, getAllCookies, removeCookie } from 'tiny-cookie'
import { SmartStorage } from "smart-core-util";

class iAuthorization {
    constructor() {

    }

    /**
     * all url parameter populated to session storage
     * all cookie data populate to session storage
     * if debug mode, load mockdata.json
     * self check
     */
    static async install() {
        let token = this.getAccessToken();
        // console.log(token)

        // 讲者协议存在sessionId过期的情况，不需要重新登录
        let pathName = window.location.pathname || ''
        if(!token && !['/errorPage'].includes(pathName)) {
            // window.open();
            let loginUrl = this.getLoginUrl();
            // console.log('loginUrl: ', loginUrl);
            window.location.href = loginUrl;
        }
    }
    static getLoginUrl() {
        let returnUrl = window.location.href;
        return process.env.VUE_APP_LOGIN_URL + "/?tenant=crossevent&platform=app&container=h5&returnUrl=" + returnUrl;
    }
    static getAccessToken() {
        let data = this.get('token');
        // if (!data) {
        //     data = getCookie('token');
        // }
        return data;
    }
    static get(key) {
        let val = sessionStorage.getItem(key) || getCookie(key);
        if (val === null) {
            return null;
        }
        if (val == "true" || val == "false") {
            return val == "true";
        }
        if (/(^{.*}$)|(^\[.*\]$)/.test(val)) {
            return JSON.parse(val);
        }
        return val;
    }
    static setiDomain() {
        let domain = document.domain;
        var re = /^(\d+)\.(\d+)\.(\d+)\.(\d+)$/; //正则表达式
        let isIP = re.test(domain);
        let isLocalhost = domain === "localhost";
        domain = isIP || isLocalhost ? domain : domain.substring(domain.indexOf(".") + 1);
        window.idomain = domain;
    }
    // 指定删除cookie，cookieList是一个数组，如果不传就删除所有cookie
    static async removeAllCookies(cookieList = []) {
        if (!cookieList.length) {
            cookieList = Object.keys(getAllCookies());
        }
        Object.keys(cookieList).forEach(function(key) {
            removeCookie(cookieList[key], {
                domain: window.idomain,
            });
            removeCookie(cookieList[key], {
                domain: document.domain,
            });
        });
    }
    // 清除缓存session/cookie 并返回到登录站点
    static async deleteLocalData() {
        SmartStorage.clear();
        await this.removeAllCookies();
        localStorage.clear();
        sessionStorage.clear();
    }
}

export default iAuthorization;