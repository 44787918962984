import { SmartStorage } from 'smart-core-util';
import iJsBridge from 'smart-javascript-bridge';
import * as tinyCookie from 'tiny-cookie';
import store from '../store/index';


let publicFun = {
  //打开内部站点参数数据处理
  openWebAppCate(appData) {
    console.log('appDataappDataappDataappData====',appData)
    let webappUrl = SmartStorage.get('uris')[appData.webappAlias.urlKey] || tinyCookie.get('Uris').Uris[appData.webappAlias.urlKey];
    let routerPath = store.state.routerPath[appData.webappAlias.urlKey] || '';
    let param = {
      tenant: tinyCookie.get('tenant'),
      miceId: appData.miceId,
      type: appData.webappAlias.type || '',
      isOnline: appData.webappAlias.isOnline || '',
      skin: SmartStorage.get('skin')
    };
    if (appData.webappAlias.urlKey == 'webapp-icar') {
      let isOnline = appData.webappAlias.isOnline || '';
      if (isOnline) {
        isOnline = '&isOnline=' + appData.webappAlias.isOnline;
      }
      webappUrl = webappUrl + routerPath + '?type=tenant' + '&userId=' + tinyCookie.get('userId') + '&tenant_id=' + tinyCookie.get('tenant_id') + '&proposalId=' + appData.proposalId + isOnline;
    } else {
      webappUrl = webappUrl + routerPath + '?paramCode=' + window.btoa(encodeURIComponent(JSON.stringify(param))).replace(/=/g, '');
    }

    let eventData = {
      province: appData.eventData.parentTxt,
      cityDictVal: appData.eventData.txt,
      city: appData.eventData.txt,
      cityId: appData.eventData.pingRegionId,
      regionId: appData.eventData.regionId,
      latitude: appData.eventData.latitude,
      longitude: appData.eventData.longitude,
      tenantId: appData.eventData.tenantId,
      tenant_code: tinyCookie.get('tenant'),
      typeDictVal: appData.eventData.typeDictVal
    };
    tinyCookie.setCookie('eventData', JSON.stringify(eventData));

    publicFun.appOpen(webappUrl, appData);
  },
  //更多功能打开内部站点参数数据处理
  openWebApplication(appData) {
    tinyCookie.setCookie('tenant', tinyCookie.get('tenant'), {
      domain: window.idomain
    });
    tinyCookie.setCookie('tenant_code', tinyCookie.get('tenant'), {
      domain: window.idomain
    });
    let webappUrl = (SmartStorage.get('uris')[appData.webappAlias.urlKey] || tinyCookie.get('Uris').Uris[appData.webappAlias.urlKey]) + (appData.webappAlias.path || '');
    appData.eventData = appData.eventData || {};
    let paramCode = {
      tenant: tinyCookie.get('tenant'),
      skin: SmartStorage.get('skin'),
      tenantId: tinyCookie.get('tenant_id'),
      isOnline: appData.webappAlias.isOnline || '',
      city: appData.eventData.txt,
      province: appData.eventData.parentTxt,
      cityId: appData.eventData.pingRegionId,
      regionId: appData.eventData.regionId,
      latitude: appData.eventData.latitude,
      longitude: appData.eventData.longitude
    };
    let params = appData.webappAlias.params || '';
    if (params) {
      let pa = JSON.parse('{"' + params.replace(/=/g, '":"').replace(/&/g, '","') + '"}');
      paramCode = Object.assign(pa, paramCode);
    }
   
    if (appData.webappAlias.urlKey == 'webapp-icar') {
      let isOnline = appData.webappAlias.isOnline || '';
      if (isOnline) {
        isOnline = '&isOnline=' + appData.webappAlias.isOnline;
      }
      webappUrl = `${webappUrl}?${params}&type=tenant&userId=${tinyCookie.get('userId')}&tenant_id=${tinyCookie.get('tenant_id')}&proposalId=${appData.proposalId}${isOnline}`;
    } else {
      webappUrl = `${webappUrl}?paramCode=${window.btoa(encodeURIComponent(JSON.stringify(paramCode))).replace(/=/g, '')}`;
    }
    publicFun.appOpen(webappUrl, appData);
  },
  //打开站点
  appOpen(webappUrl, appData) {
    if (!publicFun.openFlag) {
      return;
    }
    publicFun.openFlag = false;
    let IJsBridge = new iJsBridge({
      alert: function(e) {
        store.commit('setValueAlert', e);
      }
    });
    IJsBridge.openWebapp({
      loadUrl: webappUrl,
      callback: appData.callback
    });
    setTimeout(function() {
      publicFun.openFlag = true;
    }, 1000);
  },
  //打开站点计时触发
  openFlag: true,
  //打开外部站点
  openDianPing(url) {
    console.log(url);
    const options = {
      method: 'SMGeneral.openNewWebSiteForResult',
      postData: {
        loadUrl: url,
        browserMode: {
          navBarVisibilty: true,
          titleTextColor: '#ffffff',
          navBackgroundColor: publicFun.setColor()
        }
      },
      callback: function() {}
    };
    let IJsBridge = new iJsBridge({
      alert: function(e) {
        store.commit('setValueAlert', e);
      }
    });
    IJsBridge.call(options);
  },
  //去掉日期中间的T
  returnDate(date) {
    if (date) {
      return date.split('T')[0];
    }
    return '暂无';
  },
  returnDateAll(date) {
    if (date) {
      return date.replace('T', ' ');
    }
    return '暂无';
  },
  //首字母大写
  capitalizeFirstLetter(string) {
    if (!string) {
      return '';
    }
    return string.charAt(0).toUpperCase() + string.slice(1);
  },
  //拆分完整日期格式
  parseStoreDate(date) {
    if (date) {
      return date.split('T');
    }
    return ['', ''];
  },
  //拆分时间
  parseSec(time) {
    if (time) {
      let arr = time.split(':');
      return arr[0] + ':' + arr[1];
    }
    return '';
  },
  //动态颜色
  setColor(color) {
    if (color) {
      return color;
    }
    let baseColor = document.body.style.getPropertyValue('--themeColor');
    return baseColor;
  },
  returnNav() {
    let nav = [
      {
        txt: '会议列表',
        icon: 'iconhome',
        iconOn: 'iconhome1',
        onCheck: false,
        path: 'opHome'
      },
      {
        txt: '消息',
        icon: 'iconfankui',
        iconOn: 'iconfankui-tianchong',
        onCheck: false,
        path: 'message',
        haveCount: true,
        count: 0
      },
      {
        txt: '我的',
        icon: 'icongerenzhongxin',
        iconOn: 'icongerenzhongxin2',
        onCheck: false,
        path: 'myself'
      },
      {
        txt: '更多',
        icon: 'iconapplication',
        iconOn: 'iconyingyong',
        onCheck: false,
        path: 'application'
      }
    ];
    return nav;
  },
  //生成外部商户id
  guid() {
    function S4() {
      return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    }

    function myId() {
      return S4() + S4() + '-' + S4() + '-' + S4() + '-' + S4() + '-' + S4() + S4() + S4();
    }

    return myId();
  },
  //返回当前租户
  returnTenant() {
    let tenant = tinyCookie.get('tenant');
    switch (tenant) {
      case 'msd':
        tenant = 'msd';
        break;
      case 'novartis':
        tenant = 'novartis';
        break;
      case 'dscl':
        tenant = 'dscl';
        break;
      case 'ipsen':
        tenant = 'ipsen';
        break;
      default:
        tenant = 'normal';
        break;
    }
    return tenant;
  },
  openPopChoose(event, type) {
    event.$set(event.visible, type + 'Visible', !event.visible[type + 'Visible']);
  },
  // getDict(_this, code, callback) {
  //     PolicyTPM.proxy.call(_this, {
  //         "collection": "cfg_xdict",
  //         "method": "GetSettings",
  //         "conditions": {
  //             "db": "smartx_tpm",
  //             "filter": {
  //                 "$and": [
  //                     {"keycode": code},
  //                     {"conditions.tenants": {$in: ["*", SmartStorage.get("tenant")]}}
  //                 ]
  //             }
  //         }
  //     }).then(response => {
  //         if (response && response.success) {
  //             callback(response);
  //         }
  //     })
  // },
  getFullDate(date) {
    let year = date.getFullYear();
    let mon = date.getMonth() + 1;
    let day = date.getDate();
    return year + '-' + mon + '-' + day;
  },

};
export default publicFun;
