import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
export default new Vuex.Store({
	state: {
		start_name: {},
		end_name: {},
		extdata: {},
		passengerList: [],
		alertContent: {},
		eleCityList: [],
		eleLocalCity: '',
		eleAddress: {},
		passengerName: '',
		homeWait: '',//opHome列表触发器
        homeListOn: '',//opHome列表当前位置
	},
	mutations: {
		setValue(state, value) {
            state[value.key] = value.value;
        },
		ELE_LOCAL_CITY(state, value) {
			if (value === false) {
				state.eleLocalCity = '';
				return false;
			}
			Object.assign(state.eleLocalCity, value);
		},
		ELE_CITY_LIST(state, value) {
			if (value === false) {
				state.eleCityList = [];
				return false;
			}
			Object.assign(state.eleCityList, value);
		},
		SET_ELE_ADDRESS(state, value) {
			if (value === false) {
				state.eleAddress = {};
				return false;
			}
			Object.assign(state.eleAddress, value);
		},
		SETendName(state, value) {
			if (value === false) {
				state.end_name = {};
				return false;
			}
			Object.assign(state.end_name, value);
		}
		,
		SETstartName(state, value) {
			if (value === false) {
				state.start_name = {};
				return false;
			}
			Object.assign(state.start_name, value);
		},
		SETextdata(state, value) {
			if (value === false) {
				state.extdata = {};
				return false;
			}
			Object.assign(state.extdata, value);
		},
		SETpassengerList(state, value) {
			if (value === false) {
				state.passengerList = [];
				return false;
			}
			Object.assign(state.passengerList, value);
		},
		SETpassengerName(state, value){
			if (value === false) {
				state.passengerName = '';
				return false;
			}
			Object.assign(state.passengerName, value);
		},
		setValueAlert(state, value) {
			state.alertContent = {
				date: new Date(),
				value: value
			};
		},
	},
	actions: {
	},
	modules: {}
})
